document.addEventListener('turbolinks:load', event => {
  const elementSelectGraphic = document.querySelector('select[name="filter[graphic]"]')
  const elementSelectType = document.querySelector('select[name="filter[type]"]')
  if (elementSelectGraphic === null || elementSelectType === null) return
  elementSelectGraphic.addEventListener('change', event => {
    fillType()
    showSection()
  })

  elementSelectType.addEventListener('change', event => {
    showSection()
  })

  function fillType() {
    elementSelectType.options.length = 0
    const options = []
    const graphicValue = elementSelectGraphic.value
    const frequency = graphicValue === 'resumen_16' ? 'Trimestre' : 'Mensual'
    if (document.querySelector(`#${elementSelectGraphic.value}-mensual`) !== null) options.push([frequency, 'mensual'])
    if (document.querySelector(`#${elementSelectGraphic.value}-anual`) !== null) options.push(['Año', 'anual'])
    options.forEach(function (opt) {
      elementSelectType.options.add(new Option(opt[0], opt[1]))
    })
  }

  async function showSection() {
    hideSection()
    const itemGraphic = document.querySelector(`#${elementSelectGraphic.value}-${elementSelectType.value}`)
    if (itemGraphic === null) return
    itemGraphic.classList.remove('hidden')
    const elementGraphic = document.querySelector(`.graphic-${elementSelectGraphic.value}-${elementSelectType.value}`)
    if (elementGraphic === null) return
    const report = JSON.parse(elementGraphic.dataset.report)
    const { xAxisTitle, yAxisTitle } = elementGraphic.dataset
    const render = (await import('../dynamic_render.js')).default
    const Graphic = (await import('./Graphic.js')).default
    render(Graphic, { report, xAxisTitle, yAxisTitle }, elementGraphic)
  }

  function hideSection() {
    document.querySelectorAll('.section-graphic').forEach(el => {
      el.classList.add('hidden')
    })
  }

  elementSelectGraphic.selectedIndex = 0
  elementSelectGraphic.dispatchEvent(new Event('change'))

  document.querySelectorAll('.js-btn-download-report').forEach(el => {
    el.addEventListener('click', event => {
      const url = JSON.parse(el.dataset.url)
      const option_selected = document.querySelector(
        `.js-selected-option-download-${elementSelectGraphic.value}-${elementSelectType.value}`
      )
      if (option_selected === null) return
      const url_external = url[`${option_selected.value}`]
      if (typeof url_external === 'undefined') return
      window.open(`${url_external}`, '_blank')
    })
  })

  document.querySelectorAll('.js-rb-download').forEach(el => {
    el.addEventListener('click', event => {
      hideSectionDownload()
      const selected = el.value
      const element = document.querySelector(
        `#download-${elementSelectGraphic.value}-${elementSelectType.value}-${selected}`
      )
      element.classList.remove('hidden')
    })
  })

  function hideSectionDownload() {
    document
      .querySelectorAll(`.download-${elementSelectGraphic.value}-${elementSelectType.value}`)
      .forEach(elementHiden => {
        elementHiden.classList.add('hidden')
      })
  }

  // Get all download buttons and attach click event listener
  const downloadButtons = document.querySelectorAll('.js-btn-download-graphic')
  downloadButtons.forEach(button => {
    button.addEventListener('click', async event => {
      // Cache the SVG and selected image option elements
      const graphicElement = document.querySelector(`.graphic-${button.value}`)
      const innerSvg = graphicElement.querySelector('svg')
      const selectedImageOption = document.querySelector(`.js-selected-option-download-image-${button.value}`)
      if (selectedImageOption === null) return

      // Get the selected image option value and SVG text
      const selectedImageOptionValue = selectedImageOption.value
      const svgText = new XMLSerializer().serializeToString(innerSvg)

      // Create an image and load the SVG
      const img = new Image()
      img.onload = async function () {
        // Create a canvas and draw the image onto it
        const canvas = document.createElement('canvas')
        canvas.width = img.naturalWidth
        canvas.height = img.naturalHeight
        const ctxt = canvas.getContext('2d')
        ctxt.fillStyle = '#fff'
        ctxt.fillRect(0, 0, canvas.width, canvas.height)
        ctxt.drawImage(img, 0, 0)

        // Create a download link based on the selected image option
        const downloadLink = document.createElement('a')
        downloadLink.download = `${button.value}.${selectedImageOptionValue}`
        if (['png', 'jpeg'].includes(selectedImageOptionValue)) {
          downloadLink.href = canvas.toDataURL(`image/${selectedImageOptionValue}`)
        } else if (selectedImageOptionValue == 'svg') {
          downloadLink.href = `data:image/svg+xml;utf8,${encodeURIComponent(svgText)}`
        } else if (selectedImageOptionValue == 'pdf') {
          // Generate a PDF and save it
          const imgData = canvas.toDataURL('image/png')
          const doc = (await import('jspdf')).default('l', 'pt', 'a4')
          doc.addImage(imgData, 'PNG', 50, 50)
          doc.save(downloadLink.download)
        }

        // Download the file and remove the link
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
      }

      // Load the SVG into the image
      img.src = `data:image/svg+xml;utf8,${encodeURIComponent(svgText)}`
    })
  })
})
