document.addEventListener('turbolinks:load', async () => {
  if (document.querySelector('#js-institution__gallery') === null) return
  const el = jQuery('#js-institution__gallery')
  if (el && el.length) {
    el.unitegallery({
      gallery_images_preload_type: 'minimal',
      gallery_autoplay: false,
      slider_control_zoom: true,
      gallery_play_interval: 3000,
      slider_scale_mode: 'fill',
      slider_scale_mode_fullscreen: 'fill',
      slider_enable_bullets: false,
      gallery_autoplay: true,
      theme_enable_text_panel: true,
      gallery_theme: 'default'
    })
    el.parent().removeClass('hidden')
  }
  // NO VA A FUNCIONAR SI LA PÁGINA QUE LO LLAMA NO TIENE EL ASSET PACK DE UNITE
})
