import axios from 'axios'
import geolib from 'geolib'
import * as $ from 'jquery'
const getSpots = institutionId => {
  return axios.get(`/institutions/getaddressall/${institutionId}.json`).then(response => {
    return response.data.reduce((acc, branchOffice) => {
      return {
        ...acc,
        [branchOffice.id]: {
          latitude: parseFloat(branchOffice.latitude),
          longitude: parseFloat(branchOffice.longitude)
        }
      }
    }, {})
  })
}

$(document).on('turbolinks:load', () => {
  const link = document.querySelector('.js-institution-closest-branch-office-link')

  if (!link) {
    return
  }

  if (!('geolocation' in navigator)) {
    link.classList.add('hidden')
  }

  link.addEventListener('click', event => {
    event.preventDefault()
    const note = document.querySelector('.js-institution-closest-branch-office-note')
    const institutionIdInput = document.getElementById('institution-id')
    if (!institutionIdInput) {
      return
    }

    const institutionId = institutionIdInput.value
    getSpots(institutionId)
      .then(spots => {
        const options = {
          timeout: 6000,
          maximumAge: 0
        }
        navigator.geolocation.getCurrentPosition(
          position => {
            const {
              coords: { latitude, longitude }
            } = position
            const institution = geolib.findNearest(
              {
                latitude,
                longitude
              },
              spots
            )

            link.classList.add('hidden')
            $('.js-branch_offices_select').each(function (branchOfficeSelect) {
              branchOfficeSelect.val(institution.key).trigger('change', { type: 'closestBranchOffice' })
            })

            note.textContent = 'Sede más cercana:'
          },
          error => {
            link.classList.add('hidden')
            note.textContent = 'No se pudo encontrar tu ubicación.'
          },
          options
        )
      })
      .catch(error => {
        console.log(error.message)
      })
  })
})
